export function numForm(value) {
  return numeral(Number(value)).format("0,0.00");
}

export function numFormNoDecimals(value) {
  return numeral(Number(value)).format("0,0");
}

export function moneyFormat(value) {
  if (value) {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 2,
    }).format(value);
  }
  return "$0.00";
}

export function sinCeros(value) {
  if (value) return parseFloat(value);
  return 0;
}

export function withDecimals(value, decimals) {
  if (value) return parseFloat(value).toFixed(decimals);
  return (0).toFixed(decimals)
}

export function useRedondeo(value) {
  if (value) return parseFloat(Number(value)).toFixed(2);
  return (0).toFixed(2)
}

export function percentage(value) {
  if (value) {
    return `${parseFloat(value)}%`;
  }
  return "0%";
}

function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    // return Math[type](value);
    return +value;
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
}

export function convertDecimalToTime(decimalDays) {
  const days = Math.floor(decimalDays); // Parte entera para los días
  const hoursDecimal = (decimalDays - days) * 24; 
  const hours = Math.floor(hoursDecimal); // Parte entera para las horas
  const minutesDecimal = (hoursDecimal - hours) * 60; 
  const minutes = Math.floor(minutesDecimal); // Parte entera para los minutos
  const seconds = Math.round((minutesDecimal - minutes) * 60); // Redondeo para segundos

  return days + ' días' + ' y ' + hours + ':' + minutes + ':' + seconds;
}

export function floor10(value, exp) {
  return decimalAdjust("floor", value, exp).toFixed(2);
}
export function round10(value, exp) {
  return decimalAdjust("round", value, exp).toFixed(2);
}
export function ceil10(value, exp) {
  return decimalAdjust("ceil", value, exp).toFixed(2);
}

export function money(value) {
  if (typeof val === "numeric") {
    return `$${value.toFixed(2)}`;
  } else {
    return `$${parseFloat(value).toFixed(2)}`;
  }
}

export function formatDateYMD(dateString) {
  // Crear la fecha en UTC
  const date = new Date(dateString);

  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) {
    throw new Error("Fecha inválida");
  }

  // Convertir a la hora local de Argentina y extraer solo la fecha
  const options = { year: "numeric", month: "2-digit", day: "2-digit", timeZone: "America/Argentina/Buenos_Aires" };
  const localDate = new Intl.DateTimeFormat("es-AR", options).format(date);

  // Convertir de "21/02/2025" a "2025-02-21"
  return localDate.split("/").reverse().join("-");
}

export function formatDateTimeToTime(dateString) {
  const date = Date.parse(dateString);
  return new Intl.DateTimeFormat("es", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date);
}

export function formatDate(dateString) {
  if(dateString){
    const date = Date.parse(dateString);
    return new Intl.DateTimeFormat("es-MX", {
      locale: "es-ES",
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "2-digit",
    }).format(date);
  }
}

export function formatDateTime(dateString) {
  if(!dateString) return 'Nunca';
  const date = Date.parse(dateString);
  return new Intl.DateTimeFormat("es-MX", {
    day: "numeric",
    month: "short",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date);
}

export function formatDateTimeNumber(dateString) {
  const date = Date.parse(dateString);
  return new Intl.DateTimeFormat("es-MX", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date);
}

export function formatDateNumber(dateString) {
  const date = Date.parse(dateString);
  return new Intl.DateTimeFormat("es-MX", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  }).format(date);
}

export function dateStringTo(
  strDate,
  moment,
  day = null,
  month = null,
  year = null
) {
  const parts = strDate.split(" ");
  const dateParts = parts[0].split("/");

  const newYear = dateParts[2].replace(",", "");
  const newMonth = dateParts[1];
  const newDday = dateParts[0];

  const timeParts = parts[1].split(":");
  const hours = timeParts[0];
  const minutes = timeParts[1];
  const seconds = timeParts[2];

  if (!day) {
    day = newDday;
  } else if (day === "today") {
    day = newDday;
  } else if (day === "first") {
    day = "01";
  }

  if (!month) month = newMonth;
  if (!year) year = newYear;
  if (!moment) moment = hours + ":" + minutes + ":" + seconds;
  if (moment === "start") moment = " 00:00:00";
  if (moment === "end") moment = " 23:59:59";
  return `${year}-${month}-${day}${moment}`;
}
